// import axios from 'axios'
// import MockAdapter from 'axios-mock-adapter'

// const ServicePath = "http://localhost:8000/" 

const ServicePath = "https://staging.dentagenix.com/db/"
// const ServicePath = "https://app.dentagenix.com/db/"



export default ServicePath
